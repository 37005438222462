import React from 'react';
import './spinner.css';

const IconLoader = () => (
  <div className="logo">
    <div className="sk-cube1 sk-cube"></div>
    <div className="sk-cube2 sk-cube"></div>
    <div className="sk-cube4 sk-cube"></div>
    <div className="sk-cube3 sk-cube"></div>
    <title>Loader Logo</title>
  </div>






);

export default IconLoader;
