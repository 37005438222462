import React from 'react';

const Iconlego = () => (
  <h1 id="lego">
    Metadlux
    <title>Home</title>



  </h1>
);

export default Iconlego;
